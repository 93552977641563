import { createStore } from "vuex";

export default createStore({
  state: {
    currentRouteName: "index",
    loadingRetainCount: 0,
  },
  getters: {
    currentRouteName(state) {
      return state.currentRouteName;
    },
    loadingRetainCount(state) {
      return state.loadingRetainCount;
    },
  },
  mutations: {
    updateCurrentRouteName(state, routeName) {
      state.currentRouteName = routeName;
    },

    plushLoadingRetainCount(state) {
      state.loadingRetainCount += 1;
    },

    subtractLoadingRetainCount(state) {
      state.loadingRetainCount -= 1;
    },
  },
  actions: {},
  modules: {},
});
