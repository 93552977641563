import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Index from "@/views/index/index.vue";
import HappinessPsychologyCourse from "@/views/happiness-psychology-course/index.vue";
import PsychologyApp from "@/views/psychology-app/index.vue";
import CulturalIndustries from "@/views/cultural-industries/index.vue";
import AboutUs from "@/views/about-us/index.vue";
import JoinUs from "@/views/join-us/index.vue";
import store from "@/store";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "index",
    component: Index,
  },
  {
    path: "/course",
    name: "happinessPsychologyCourse",
    component: HappinessPsychologyCourse,
  },
  {
    path: "/app",
    name: "psychologyApp",
    component: PsychologyApp,
  },
  {
    path: "/cultural",
    name: "culturalIndustries",
    component: CulturalIndustries,
  },
  {
    path: "/about",
    name: "aboutUs",
    component: AboutUs,
  },
  {
    path: "/join",
    name: "joinUs",
    component: JoinUs,
  },
  {
    path: "/tutorcollege",
    name: "tutorCollege",
    component: () => import("@/views/tutor-college-details/index.vue"),
  },
  {
    path: "/coursedetails",
    name: "courseDetails",
    component: () => import("@/views/course-details/index.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  console.log(to.name);

  store.commit("updateCurrentRouteName", to.name);
  next();
});

export default router;
