import { Interceptors } from "./interceptors";
import { PageEntity, ResponseEntity } from "./entity";
import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import store from "@/store/index";
import { ElLoading, ElMessage } from "element-plus";

export enum HttpMethod {
  GET,
  POST,
  PUT,
  DELETE,
  OPTIONS,
}

export class Request {
  axiosInstance: AxiosInstance;

  notShowToastState = [-4];

  constructor() {
    this.axiosInstance = new Interceptors().axiosInstance;
  }

  /**
   * 获取对象
   * @param url 请求地址
   * @param params 参数
   * @param isShowLoadingView 是否显示 View.
   * @returns
   */
  getObject<T>(
    url: string,
    params: object = {},
    isShowLoadingView: boolean = true
  ) {
    return new Promise<T>((resolve, reject) => {
      if (isShowLoadingView) {
        store.commit("plushLoadingRetainCount");
        const loadingInstance = ElLoading.service({
          lock: true,
          text: "加载中...",
          background: "rgba(0, 0, 0, 0.7)",
        });
      }
      this.axiosInstance
        .get<ResponseEntity<T>>(url, { params: params })
        .then((res: any) => {
          this.parserResponse(res.data, resolve, reject);
        })
        .catch((err: Error) => {
          reject(err.message);
          ElMessage.error(err.message);
        })
        .finally(() => {
          if (isShowLoadingView) {
            store.commit("subtractLoadingRetainCount");
            if (store.getters.loadingRetainCount == 0) {
              ElLoading.service({
                lock: true,
                text: "加载中...",
                background: "rgba(0, 0, 0, 0.7)",
              }).close();
            }
          }
        });
    });
  }

  /**
   * 获取列表数据
   * @param url 地址
   * @param params 参数
   * @returns
   */
  getList<T>(url: string, params: object = {}) {
    return this.getObject<Array<T>>(url, params);
  }

  /**
   * 获取分页数据
   * @param url 地址
   * @param params 参数
   * @returns
   */
  getPage<T>(url: string, params: object = {}) {
    return this.getObject<PageEntity<T>>(url, params);
  }

  postObject<T>(url: string, params = {}, isShowLoadingView: boolean = true) {
    return new Promise<T>((resolve, reject) => {
      if (isShowLoadingView) {
        store.commit("plushLoadingRetainCount");
        const loadingInstance = ElLoading.service({
          lock: true,
          text: "加载中...",
          background: "rgba(0, 0, 0, 0.7)",
        });
      }
      this.axiosInstance
        .post<ResponseEntity<T>>(url, params, {})
        .then((res) => {
          this.parserResponse(res.data, resolve, reject);
        })
        .catch((err) => {
          reject(err.message);
        })
        .finally(() => {
          if (isShowLoadingView) {
            store.commit("subtractLoadingRetainCount");
            if (store.getters.loadingRetainCount == 0) {
              ElLoading.service({
                lock: true,
                text: "加载中...",
                background: "rgba(0, 0, 0, 0.7)",
              }).close();
            }
          }
        });
    });
  }

  putObject<T>(url: string, params = {}) {
    return new Promise<T>((resolve, reject) => {
      this.axiosInstance
        .put<ResponseEntity<T>>(url, params, {})
        .then((res) => {
          this.parserResponse(res.data, resolve, reject);
        })
        .catch((err) => {
          reject(err.message);
        });
    });
  }

  private parserResponse<T>(
    response: ResponseEntity<T>,
    resolve: any,
    reject: any
  ) {
    if (response.code === 1) {
      resolve(response.data);
    } else if (this.notShowToastState.includes(response.code)) {
      // 将异常重新发射出去.
      reject(response.message);
    } else {
      if (
        response.message !== null &&
        response.message !== undefined &&
        response.message != ""
      ) {
        // 将异常重新发射出去.
        reject(response.message);
        ElMessage.error(response.message);
      }
    }
  }
}

export default new Request();
