import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import { createMetaManager } from "vue-meta";
import "@/assets/reset.css";

import "amfe-flexible";
import "element-plus/dist/index.css";
createApp(App)
  .use(store)
  .use(router)
  .use(
    createMetaManager(false, {
      meta: { tag: "meta", nameless: true },
    })
  )
  .mount("#app");
